<template>
  <div class="w-2/3 relative left-1/2 transform -translate-x-1/2" v-if="text">
    <lottie-animation
      path="lotties/empty.json"
      :loop="false"
      :autoPlay="true"
      :speed="1"
      :width="512"
      :height="512"
    />
    <h2
      :class="{
        'text-white': dark,
        'text-black': !dark,
      }"
      class="text-2xl text-center"
    >
      {{ text }}
    </h2>
  </div>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
  name: "EmptyContent",
  components: { LottieAnimation },
  props: {
    text: {
      type: String,
      required: true,
    },
    dark: {
      type: Boolean,
      default: () => true,
    },
  },
};
</script>

<style scoped></style>
