<template>
  <div class="text-black flex">
    <Sidebar />
    <div class="font-semibold flex-1 h-screen" v-if="dataFetched">
      <Navbar title="Users" />
      <div
        id="users-content"
        class="bg-nColorFillGray border border-nColorStrokeGray rounded-xl m-6 users"
        v-if="dataFetched"
      >
        <div
          class="filters flex place-items-center justify-end py-6 px-4 border-b border-gray-300 text-gray-800"
          v-if="dataFetched"
        >
          <div class="filters-box flex flex-row gap-4" v-if="dataFetched">
            <div class="search-box">
              <span class="font-semibold">Search by name or email</span>
              <form @submit.prevent="applyFilter()" novalidate>
                <div class="relative mx-auto text-gray-600">
                  <input
                    class="border border-blue-600 bg-white w-64 h-12 px-3 pr-16 rounded-lg text-ml focus:outline-none"
                    type="search"
                    name="q"
                    placeholder="Search"
                    id="filterText"
                    v-model="filterText"
                  />
                  <button
                    type="submit"
                    class="absolute right-0 top-0 pt-4 pr-4"
                  >
                    <svg
                      class="text-blue-600 h-4 w-4 fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      version="1.1"
                      id="Capa_1"
                      x="0px"
                      y="0px"
                      viewBox="0 0 56.966 56.966"
                      style="enable-background: new 0 0 56.966 56.966"
                      xml:space="preserve"
                      width="512px"
                      height="512px"
                    >
                      <path
                        d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"
                      />
                    </svg>
                  </button>
                </div>
              </form>
            </div>
            <button
              @click.prevent="applyFilter()"
              type="button"
              class="bg-white text-blue-600 font-bold px-4 py-2.5 uppercase rounded max-h-12 mt-6 border border-blue-600 hover:text-white hover:bg-blue-600 place-self-center"
            >
              Apply
            </button>
            <button
              @click.prevent="clearFilter()"
              type="button"
              class="bg-white text-red-600 font-bold px-4 py-2.5 uppercase rounded max-h-12 mt-6 border border-red-600 hover:text-white hover:bg-red-600 place-self-center"
            >
              Clear
            </button>
          </div>
        </div>
        <div
          class="flex flex-col place-items-end px-4 pb-4"
          v-if="dataFetched && data.length > 0"
        >
          <VTable
            :hideSortIcons="true"
            class="w-full table-auto bg-white text-black border-collapse mt-10"
            :data="data"
            sortIconPosition="before"
            sortHeaderClass="flex p-4 justify-center items-center w-full"
            :pageSize="pagination.results_per_page"
          >
            <template #head="">
              <tr class="text-md text-gray-600 text-left border">
                <th></th>
                <th class="p-4">Name</th>
                <th>Email</th>
                <th>Profile</th>
              </tr>
            </template>

            <template #body="{ rows }">
              <v-tr
                class="border font-normal"
                v-for="row in rows"
                :key="row._id"
                :row="row"
              >
                <td class="flex justify-center p-4">
                  <img :src="row.profile_picture" class="h-12 rounded-xl" />
                </td>
                <td>
                  <div>
                    <div class="font-semibold">{{ row.name }}</div>
                  </div>
                </td>
                <td>
                  <div>{{ row.email }}</div>
                </td>
                <td>
                  <div
                    @click.prevent="preview(row._id)"
                    class="bg-white border border-nButtonGreen px-2.5 py-1 font-rajdhani w-28 text-center text-nButtonGreen font-medium rounded hover:bg-nButtonGreen hover:text-white"
                  >
                    View Profile
                  </div>
                </td>
              </v-tr>
            </template>
          </VTable>
        </div>
        <div class="empty-content -mt-6" v-else>
          <EmptyContent text="No users found" :dark="false" />
        </div>
        <div class="pagination-controls text-right text-bgcolor mr-4 pb-4">
          <div
            class="text-gray-400 mr-4 inline-block text-sm"
            v-if="pagination.total_pages != 0"
          >
            Showing page {{ pagination.page }}/{{ pagination.total_pages }}
          </div>
          <div v-if="parseInt(pagination.page) > 1" class="inline-block">
            <router-link
              :to="{
                name: 'Users',
                query: {
                  sort: sortSlug,
                  page: parseInt(pagination.page) - 1,
                },
              }"
            >
              <button
                class="focus:outline-none py-2 px-4 mr-1.5 rounded bg-blue-600 text-white text-sm font-semibold"
                type="button"
              >
                Prev
              </button>
            </router-link>
          </div>
          <div v-else class="inline-block">
            <button
              class="cursor-not-allowed py-2 px-4 mr-1.5 rounded bg-gray-300 text-white text-sm font-semibold"
              type="button"
              disabled
            >
              Prev
            </button>
          </div>
          <div
            v-if="parseInt(pagination.page) < parseInt(pagination.total_pages)"
            class="inline-block"
          >
            <router-link
              :to="{
                name: 'Users',
                query: {
                  sort: sortSlug,
                  page: parseInt(pagination.page) + 1,
                },
              }"
            >
              <button
                class="focus:outline-none py-2 px-4 rounded bg-blue-600 text-white text-sm font-semibold"
                type="button"
              >
                Next
              </button>
            </router-link>
          </div>
          <div v-else class="inline-block">
            <button
              class="cursor-not-allowed py-2 px-4 rounded bg-gray-300 text-white text-sm font-semibold"
              type="button"
              disabled
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="previewModal.show">
      <NHAPreviewModal
        @closeModal="previewModal.show = false"
        :modal="previewModal"
        :userId="previewUserId"
      />
    </div>
  </div>
</template>
<script>
import Sidebar from "@/components/Common/Sidebar/Standard";
import Navbar from "@/components/Common/Navbar/Standard";
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import "@vueform/multiselect/themes/default.css";
import EmptyContent from "@/components/Elements/EmptyContent.vue";
import NHAPreviewModal from "@/components/Modals/NHA/Index.vue";

export default {
  name: "Users",
  props: ["page"],
  components: {
    Sidebar,
    Navbar,
    EmptyContent,
    NHAPreviewModal,
  },
  data() {
    return {
      dataFetched: false,
      data: null,
      currentPage: 1,
      totalPages: 0,
      pagination: null,
      filterText: "",
      previewModal: {
        show: false,
      },
      previewUserId: null,
    };
  },
  mounted() {
    this.filterText = this.$route.query.filter || null;
    this.currentPage = this.$route.query.page || this.currentPage;

    var params = {
      page: this.currentPage,
    };
    if (this.filterText) {
      params["filter_text"] = this.filterText;
    }

    ApiService.get(apiResource.users.getUserProfiles, params).then(
      ({ data }) => {
        this.dataFetched = true;
        this.data = data.data.users;
        this.pagination = data.data.pagination;
        this.currentPage = parseInt(this.pagination.page);
      }
    );
  },
  methods: {
    preview(userId) {
      this.previewModal.show = true;
      this.previewUserId = userId;
      document.body.style.overflow = "hidden";
    },
    applyFilter() {
      let query = {};
      if (this.filterText) {
        query["filter"] = this.filterText;
      }
      var params = {
        page: this.currentPage,
      };
      this.$router.push({
        name: "UserProfiles",
        params: params,
        query: query,
      });
    },
    clearFilter() {
      this.filterText = "";
      this.$router.replace({
        name: "UserProfiles",
        params: {
          page: 1,
        },
        query: {},
      });
    },
    toggleSort() {
      let query = {};

      if (this.filterText) {
        query["filter"] = this.filterText;
      }

      this.$router.push({
        name: "UserProfiles",
        params: {
          page: 1,
        },
        query: query,
      });
    },
  },
};
</script>

<style scoped lang="postcss">
.vt-selected {
  background: rgb(230, 239, 255);
}
.fade-enter-from {
  opacity: 0;
}

.fade-enter-active {
  transition: all 0.5s ease-out;
}

.fade-leave-to {
  opacity: 0;
}

.fade-leave-active {
  transition: all 0.5s ease-in;
}
input[type="search"]::-webkit-search-cancel-button {
  display: none;
}
</style>
