<template>
  <div
    v-if="values"
    class="px-4 pt-5 pb-4 mb-0 bg-white border border-nColorStrokeGray md:border-0 rounded-xl flex justify-center"
  >
    <div class="grid justify-items-center p-2 md:p-0">
      <div class="grid md:flex">
        <div class="md:w-3/5">
          <div class="h-full grid items-center justify-center">
            <div>
              <span class="text-2xl md:text-2xl">Hi, I Am</span><br />
              <div class="text-5xl md:text-8xl font-semibold">
                {{ values.user_name }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="grid justify-items-center md:w-2/5 md:flex md:justify-end mt-2 md:mt-0"
        >
          <div class="flex justify-center" v-if="values.profile_picture">
            <img
              class="w-2/3 object-contain"
              ref="profileImage"
              :src="values.profile_picture"
              alt=""
            />
          </div>
          <div
            v-else
            class="upload-box flex justify-center border-4 border-nLightYellow"
          >
            <div class="flex justify-center items-center h-full">
              <img
                class="w-2/3"
                ref="profileImage"
                :src="defaults.avatar"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="md:w-5/6 bg-nColorMustard p-4 md:p-10 text-white grid justify-center"
        v-if="
          values.personal.designation ||
          values.personal.based_out_of ||
          values.personal.years_of_exp ||
          values.personal.last_job
        "
      >
        <div class="pos mb-4">
          <p class="mb-2">
            <span v-if="values.personal.designation">
              I am a
              <span
                class="inline-block mx-1 md:mx-2 text-center text-base md:text-xl font-semibold"
              >
                {{ values.personal.designation }}
              </span>
            </span>
            <span v-if="values.personal.based_out_of">
              based out of
              <span
                class="inline-block mx-1 md:mx-2 text-center text-base md:text-xl font-semibold"
              >
                {{ values.personal.based_out_of }}
              </span>
            </span>
          </p>
        </div>
        <div class="exp" v-if="values.personal.years_of_exp">
          <p>
            I have
            <span
              class="inline-block mx-1 md:mx-2 text-center text-base md:text-xl font-semibold"
            >
              {{ values.personal.years_of_exp }}
            </span>
            years of experience and my last sting was at
            <span
              class="inline-block text-center text-base md:text-xl font-semibold mx-1 md:mx-2"
            >
              {{ values.personal.last_job }}
            </span>
          </p>
        </div>
      </div>
      <div
        id="personal"
        class="grid justify-center justify-items-center md:flex w-full mt-10"
        v-if="values.personal.family_info"
      >
        <div class="grid md:w-2/3">
          <div>
            <span class="text-3xl md:text-6xl font-semibold"> Personal </span>
            <hr class="w-11/12 float-right border-red-500" />
          </div>
          <div class="pt-4 md:pt-10 block md:hidden">
            <div
              v-if="values.personal.animation"
              class="upload-box border-4 border-nLightGreen p-3 flex justify-center"
            >
              <img class="h-full" :src="values.personal.animation" alt="" />
            </div>
            <div v-else>
              <img
                class="upload-box object-contain border-4 border-nLightGreen p-3"
                :src="defaults.personal"
                alt=""
              />
            </div>
          </div>
          <div>
            <div
              class="mt-4 md:mt-10 bg-nColorBlue p-10 text-white md:flex items-start"
            >
              <p class="inline-block text-base md:mx-5">
                My family consists of
              </p>
              <span class="font-semibold mx-1 md:mx-0">
                {{ values.personal.family_info }}
              </span>
            </div>
          </div>
        </div>
        <div class="pt-4 md:pt-10 hidden md:block">
          <div v-if="values.personal.animation">
            <img
              class="upload-box object-contain border-4 border-nLightGreen p-3"
              :src="values.personal.animation"
              alt=""
            />
          </div>
          <div v-else>
            <img
              class="upload-box object-contain border-4 border-nLightGreen p-3"
              :src="defaults.personal"
              alt=""
            />
          </div>
        </div>
      </div>
      <div
        id="interests"
        class="grid justify-center md:flex w-full mt-4 md:mt-10"
        v-if="
          values.interests.wanted_to_be || values.interests.learned_last_year
        "
      >
        <div class="md:pt-10 hidden md:block">
          <div v-if="values.interests.animation">
            <img
              class="upload-box object-contain border-4 border-nLightPurple p-3"
              :src="values.interests.animation"
              alt=""
            />
          </div>
          <div v-else>
            <img
              class="upload-box object-contain border-4 border-nLightPurple p-3"
              :src="defaults.interests"
              alt=""
            />
          </div>
        </div>
        <div class="grid md:w-2/3 mt-4 md:mt-0">
          <div>
            <span class="text-3xl md:text-6xl font-semibold flex justify-end">
              Interests
            </span>
            <hr class="w-11/12 float-left border-red-500" />
          </div>
          <div class="pt-4 md:pt-10 block md:hidden">
            <div
              v-if="values.interests.animation"
              class="upload-box border-4 border-nLightPurple p-3 flex justify-center"
            >
              <img class="h-full" :src="values.interests.animation" alt="" />
            </div>
            <div v-else>
              <img
                class="upload-box object-contain border-4 border-nLightPurple p-3"
                :src="defaults.interests"
                alt=""
              />
            </div>
          </div>
          <div class="mt-4 md:mt-0">
            <div class="bg-nColorMustard p-10 text-white flex items-start">
              <div>
                <div v-if="values.interests.wanted_to_be">
                  <p class="inline-block text-base md:mx-2">
                    When I was little, I wanted to be
                  </p>
                  <span class="font-semibold">
                    {{ values.interests.wanted_to_be }}
                  </span>
                </div>
                <div v-if="values.interests.learned_last_year">
                  <p class="inline-block text-base md:mx-2 mt-4">
                    In the last year, I learned to
                  </p>
                  <span class="font-semibold mx-1 md:mx-0">
                    {{ values.interests.learned_last_year }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="favorites"
        class="grid justify-center justify-items-center md:flex w-full mt-10"
        v-if="
          values.favorites.book ||
          values.favorites.tv_show ||
          values.favorites.food ||
          values.favorites.cheer_for ||
          values.favorites.song
        "
      >
        <div class="grid w-full md:w-2/3">
          <div>
            <span class="text-3xl md:text-6xl font-semibold"> Favorites </span>
            <hr class="w-11/12 float-right border-red-500" />
          </div>
          <div class="pt-4 md:pt-10 block md:hidden">
            <div
              v-if="values.favorites.animation"
              class="upload-box border-4 border-nLightYellow p-3 flex justify-center"
            >
              <img class="h-full" :src="values.favorites.animation" alt="" />
            </div>
            <div v-else>
              <img
                class="upload-box object-contain border-4 border-nLightYellow p-3"
                :src="defaults.favorites"
                alt=""
              />
            </div>
          </div>
          <div>
            <div class="mt-4 md:mt-10 bg-nColorBlue p-10 text-white grid gap-4">
              <div v-if="values.favorites.book">
                <p class="inline-block text-base md:mx-2">Book I love</p>
                <span class="font-semibold mx-1 md:mx-0">
                  {{ values.favorites.book }}
                </span>
              </div>
              <div v-if="values.favorites.tv_show">
                <p class="inline-block text-base md:mx-2">
                  My comfort binge TV show
                </p>
                <span class="font-semibold mx-1 md:mx-0">
                  {{ values.favorites.tv_show }}
                </span>
              </div>
              <div v-if="values.favorites.food">
                <p class="inline-block text-base md:mx-2">
                  My favorite kinda food
                </p>
                <span class="font-semibold mx-1 md:mx-0">
                  {{ values.favorites.food }}
                </span>
              </div>
              <div v-if="values.favorites.cheer_for">
                <p class="inline-block text-base md:mx-2">I cheer for</p>
                <span class="font-semibold mx-1 md:mx-0">
                  {{ values.favorites.cheer_for }}
                </span>
              </div>
              <div v-if="values.favorites.song">
                <p class="inline-block text-base md:mx-2">Listening on Loop</p>
                <span class="font-semibold mx-1 md:mx-0">
                  {{ values.favorites.song }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-4 md:pt-10 hidden md:block">
          <div v-if="values.favorites.animation">
            <img
              class="upload-box object-contain border-4 border-nLightYellow p-3"
              :src="values.favorites.animation"
              alt=""
            />
          </div>
          <div v-else>
            <img
              class="upload-box object-contain border-4 border-nLightYellow p-3"
              :src="defaults.favorites"
              alt=""
            />
          </div>
        </div>
      </div>
      <div
        id="skills"
        class="grid justify-center md:flex w-full mt-4 md:mt-10"
        v-if="
          values.skills.unbeatable_at ||
          values.skills.mad_skills_in ||
          values.skills.good_at_playing ||
          values.skills.languages
        "
      >
        <div class="md:pt-10 hidden md:block">
          <div v-if="values.skills.animation">
            <img
              class="upload-box object-contain border-4 border-nLightPink p-3"
              :src="values.skills.animation"
              alt=""
            />
          </div>
          <div v-else>
            <img
              class="upload-box object-contain border-4 border-nLightPink p-3"
              :src="defaults.skills"
              alt=""
            />
          </div>
        </div>
        <div class="grid md:w-2/3 mt-4 md:mt-0">
          <div>
            <span class="text-3xl md:text-6xl font-semibold flex justify-end">
              Skills
            </span>
            <hr class="w-11/12 float-left border-red-500" />
          </div>
          <div class="pt-4 md:pt-10 block md:hidden">
            <div
              v-if="values.skills.animation"
              class="upload-box border-4 border-nLightPink p-3 flex justify-center"
            >
              <img class="h-full" :src="values.skills.animation" alt="" />
            </div>
            <div v-else>
              <img
                class="upload-box object-contain border-4 border-nLightPink p-3"
                :src="defaults.skills"
                alt=""
              />
            </div>
          </div>
          <div class="mt-4">
            <div class="bg-nColorMustard p-10 text-white flex items-start">
              <div>
                <div v-if="values.skills.unbeatable_at">
                  <p class="inline-block text-base md:mx-2">
                    No one can beat me at
                  </p>
                  <span class="font-semibold mx-1 md:mx-0">
                    {{ values.skills.unbeatable_at }}
                  </span>
                </div>
                <div v-if="values.skills.mad_skills_in">
                  <p class="inline-block text-base md:mx-2 mt-4">
                    I also have mad skills in
                  </p>
                  <span class="font-semibold mx-1 md:mx-0">
                    {{ values.skills.mad_skills_in }}
                  </span>
                </div>
                <div v-if="values.skills.good_at_playing">
                  <p class="inline-block text-base md:mx-2 mt-4">
                    I am good at playing
                  </p>
                  <span class="font-semibold mx-1 md:mx-0">
                    {{ values.skills.good_at_playing }}
                  </span>
                </div>
                <div v-if="values.skills.languages">
                  <p class="inline-block text-base md:mx-2 mt-4">
                    and speaking
                  </p>
                  <span class="font-semibold mx-1 md:mx-0">
                    {{ values.skills.languages }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="inspiration"
        class="grid justify-center justify-items-center md:flex w-full mt-10"
        v-if="
          values.inspiration.all_time_hero ||
          values.inspiration.cant_live_without_tech ||
          values.inspiration.friends_headlines_at
        "
      >
        <div class="grid md:w-2/3">
          <div>
            <span class="text-3xl md:text-6xl font-semibold">
              Inspiration
            </span>
            <hr class="w-11/12 float-right border-red-500" />
          </div>
          <div class="pt-4 md:pt-10 block md:hidden">
            <div
              v-if="values.inspiration.animation"
              class="upload-box border-4 border-nLightGreen p-3 flex justify-center"
            >
              <img class="h-full" :src="values.inspiration.animation" alt="" />
            </div>
            <div v-else>
              <img
                class="upload-box object-contain border-4 border-nLightGreen p-3"
                :src="defaults.inspiration"
                alt=""
              />
            </div>
          </div>
          <div>
            <div class="mt-4 md:mt-10 bg-nColorBlue p-10 text-white grid gap-4">
              <div v-if="values.inspiration.all_time_hero">
                <p class="inline-block text-base md:mx-2">
                  My all time hero is
                </p>
                <span class="font-semibold mx-1 md:mx-0">
                  {{ values.inspiration.all_time_hero }}
                </span>
              </div>
              <div v-if="values.inspiration.cant_live_without_tech">
                <p class="inline-block text-base md:mx-2">
                  The tech invention I can't live without is
                </p>
                <span class="font-semibold mx-1 md:mx-0">
                  {{ values.inspiration.cant_live_without_tech }}
                </span>
              </div>
              <div v-if="values.inspiration.friends_headlines_at">
                <p class="inline-block text-base md:mx-2">
                  I will be making friends and headlines at
                </p>
                <span class="font-semibold mx-1 md:mx-0">
                  {{ values.inspiration.friends_headlines_at }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-4 md:pt-10 hidden md:block">
          <div v-if="values.inspiration.animation">
            <img
              class="upload-box object-contain border-4 border-nLightGreen p-3"
              :src="values.inspiration.animation"
              alt=""
            />
          </div>
          <div v-else>
            <img
              class="upload-box object-contain border-4 border-nLightGreen p-3"
              :src="defaults.inspiration"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NHAPreviewOption2",
  props: ["values", "defaults"],
  created() {},
  components: {},
  mounted() {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.green {
  background-color: #e4f7f4;
}

.yellow {
  background-color: #fff8de;
}

.purple {
  background-color: #e9ecff;
}
.pink {
  background-color: #fff0f5;
}

.solid-green {
  background-color: #11bca2;
}

.solid-pink {
  background-color: #ff5670;
}

.purple-btn {
  color: #7789e0;
}

.yellow-btn {
  color: #d8c27d;
}

.yellow-btn-bg {
  background-color: #d8c27d;
}

.pink-btn {
  color: #d89cae;
}

.yellow-text {
  color: #fdb400;
}

.green-text {
  color: #11bca2;
}

.pink-text {
  color: #ff5670;
}

.purple-text {
  color: #3d50e0;
}

.green-btn {
  color: #85c6ba;
}

.gray {
  background-color: rgba(245, 243, 243, 0.856);
}

.text-color {
  color: #777777;
}

.upload-box {
  width: 100%;
  height: 240px;
}
</style>
